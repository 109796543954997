import ConsentManager from "@mainiotech/dataconsent-bootstrap";
import i18n from "@mainiotech/dataconsent-i18n";
import { services } from "@mainiotech/dataconsent-core";
import getServices from "@mainiotech/dataconsent-serviceregistry";
import { documentReady } from "./utils";

export default {
  init: () => {
    const locale = i18n.getLanguage();

    const cm = new ConsentManager({
      categories: services(
        getServices(locale),
        i18n.getTranslations(),
        ["googleanalytics"]
      ),
    });
    document.documentElement.cm = cm;

    documentReady().then(() => {
      document.querySelectorAll("button[data-dc-trigger='settings']").forEach((button) => {
        button.addEventListener("click", () => {
          cm.showSettings();
        });
      });
    });

    return cm;
  },
};
