import supportsWebp from "supports-webp";

import ContactForm from "./public/contact-form";
import botDetector  from "./public/bot-detector-lite";
import consentManager from "./public/consent";
import { documentReady } from "./public/utils";

// Start the token generation for the user when they hit the site so that the
// token is ready when they want to submit forms. This keeps the bots less eager
// to try to spam us.
botDetector.generateToken();

consentManager.init();

supportsWebp.then((supported) => {
  if (supported) {
    document.documentElement.classList.add("support-webp-1");
  } else {
    document.documentElement.classList.add("support-webp-0");
  }
});

const contentLoaded = () => {
  const contactForm = document.getElementById("contact_form");
  if (contactForm) {
    contactForm.dataset.controller = new ContactForm(contactForm);
  }

  const menu = document.getElementById("mobile-menu");
  const menuLinks = document.querySelector(".nav-menu");

  menu.addEventListener("click", function() {
    menu.classList.toggle("is-active");
    menuLinks.classList.toggle("active");
  });
}

documentReady().then(() => {
  contentLoaded();
});
