import { v4 as uuidv4 } from "uuid";
import md5 from "crypto-js/md5";

class BotDetector {
  constructor() {
    this.generating = false;
    this.token = null;
    this.events = new EventTarget();

    const stamp = localStorage.getItem("stamp");
    this.hadStamp = false;
    if (stamp) {
      this.loadStamp = stamp;
      this.hadStamp = true;
    } else {
      this.loadStamp = Date.now();
      localStorage.setItem("stamp", this.loadStamp);
    }
  }

  getUuid() {
    let uuid = localStorage.getItem("uuid");
    if (!uuid) {
      uuid = uuidv4();
      localStorage.setItem("uuid", uuid);
    }

    return uuid;
  }

  generateToken() {
    if (this.generating) {
      return;
    }

    // Check if the token has been already generated and stored for this user.
    const localToken = localStorage.getItem("submitToken");
    if (localToken && this.hadStamp) {
      this.token = localToken;

      this.events.dispatchEvent(new CustomEvent("token"));
      return;
    }

    this.generating = true;
    this.token = null;

    // Wait time between 2-4 seconds
    const waitTime = Math.floor(Math.random() * 3) + 2;
    setTimeout(() => {
      this.generating = false;
      this.token = md5(`${this.loadStamp}:${this.getUuid()}`).toString();
      localStorage.setItem("submitToken", this.token);

      this.events.dispatchEvent(new CustomEvent("token"));
    }, waitTime * 1000);
  }

  getToken() {
    return new Promise((resolve) => {
      if (!this.token) {
        this.generateToken();
      }

      if (this.generating) {
        this.events.addEventListener("token", () => {
          resolve(this.token);
        });
      } else {
        resolve(this.token);
      }
    });
  }

  getLoadStamp() {
    return this.loadStamp;
  }

  getFormToken() {
    return new Promise((resolve) => {
      this.getToken().then((token) => {
        const loadStamp = this.getLoadStamp();
        const uuid = this.getUuid();
        resolve(`${uuid}:${loadStamp}:${token}`);
      })
    });
  }
}

// A singleton of the detector
const instance = new BotDetector();

export default instance;
