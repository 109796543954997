import botDetector from "./bot-detector-lite";

class ContactForm {
  constructor(element) {
    this.element = element;

    this.element.addEventListener("submit", (ev) => {
      ev.preventDefault();
      this.handleSubmit();
    });
  }

  async handleSubmit() {
    // Disable the submit button while token is being calculated
    this.disableSubmit();

    const formData = new FormData(this.element);
    const formJson = Object.fromEntries(new Map(formData.entries()));
    formJson._token = await botDetector.getFormToken();
    formJson.lang = document.documentElement.lang;

    fetch("/api/contact", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(formJson),
    }).then(async (res) => {
      if (res.status !== 200) {
        this.displayErrorState();
        this.enableSubmit();
        return;
      }

      const data = await res.json();
      if (data.message !== "success") {
        this.displayErrorState();
        this.enableSubmit();
        return;
      }

      this.displaySuccessState();
      this.hideFormBody();
    }).catch(() => {
      this.displayErrorState();
      this.enableSubmit();
    });
  }

  disableSubmit() {
    const submit = this.element.querySelector("button[type='submit']");
    if (!submit) {
      return;
    }

    submit.disabled = true;
  }

  enableSubmit() {
    const submit = this.element.querySelector("button[type='submit']");
    if (!submit) {
      return;
    }

    submit.disabled = false;
  }

  hideFormBody() {
    const body = this.element.querySelector(".form-body");
    if (!body) {
      return;
    }

    body.classList.add("d-none");
  }

  displayErrorState() {
    const error = this.element.querySelector(".form-error");
    if (!error) {
      return;
    }

    error.classList.remove("d-none");
  }

  hideErrorState() {
    const error = this.element.querySelector(".form-error");
    if (!error) {
      return;
    }

    error.classList.add("d-none");
  }

  displaySuccessState() {
    this.hideErrorState();

    const success = this.element.querySelector(".form-success");
    if (!success) {
      return;
    }

    success.classList.remove("d-none");
  }
}

export default ContactForm;
